import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Providers from './system/Providers';
import AuthenticatedRoutes from './system/AuthenticatedRoutes';
import ScrollToTop from './system/ScrollToTop';

import Layout from './pages/Layout';
import Loading from './pages/Loading';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Search = lazy(() => import('./pages/Search'));
const Locations = lazy(() => import('./pages/Locations'));
const AccountLogin = lazy(() => import('./pages/Account/Login'));
const WebsiteSecurity = lazy(() => import('./pages/Legal/WebsiteSecurity'));

const Legal = lazy(() => import('./pages/Legal'));
const LegalCookie = lazy(() => import('./pages/Legal/Cookie'));
const LegalTerms = lazy(() => import('./pages/Legal/Terms'));
const LegalPrivacy = lazy(() => import('./pages/Legal/Privacy'));
const LegalSafeOnlineDating = lazy(() => import('./pages/Legal/SafeOnlineDating'));

const Account = lazy(() => import('./pages/Account'));

const MemberProfile = lazy(() => import('./pages/MemberProfile'));

const Profile = lazy(() => import('./pages/Profile'));
const ProfilePhotos = lazy(() => import('./pages/Profile/Photos'));
const ProfileVideos = lazy(() => import('./pages/Profile/Videos'));

const Inbox = lazy(() => import('./pages/Inbox'));
const InboxMessage = lazy(() => import('./pages/Inbox/Message'));

const Notification = lazy(() => import('./pages/Notification'));

const NoPage = lazy(() => import('./pages/NoPage'));

function App() {
  return <Providers>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <ScrollToTop />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route element={<AuthenticatedRoutes />}>
              <Route path="/account" element={<Account />} />

              <Route path="/member/:username" element={<MemberProfile />} />

              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/photos" element={<ProfilePhotos />} />
              <Route path="/profile/videos" element={<ProfileVideos />} />

              <Route path="/inbox" element={<Inbox />} />
              <Route path="/inbox/message/:id" element={<InboxMessage />} />

              <Route path="/notifications" element={<Notification />} />

              <Route path="/search" element={<Search />} />
              <Route path="/search/*" element={<Search />} />
            </Route>

            <Route path='/about' element={<About />} />
            <Route path='/register' element={<Home />} />
            <Route path="/abuse" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/feedback" element={<Contact />} />
            <Route path="/report" element={<Contact />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/login" element={<AccountLogin />} />
            <Route path="/account/activation/failed" element={<AccountLogin />} />
            <Route path="/account/activation/activated" element={<AccountLogin />} />
            <Route path="/account/activation/complete" element={<AccountLogin />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/legal/cookie" element={<LegalCookie />} />
            <Route path="/legal/privacy" element={<LegalPrivacy />} />
            <Route path="/legal/terms" element={<LegalTerms />} />
            <Route path="/legal/safe-online-dating" element={<LegalSafeOnlineDating />} />
            <Route path="/legal/website-security" element={<WebsiteSecurity />} />
            <Route path="/safe-online-dating" element={<LegalSafeOnlineDating />} />
            <Route path="/website-security" element={<WebsiteSecurity />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Providers>;
}

export default App;
