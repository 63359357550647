import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import DropdownField from '../_Types/DropdownField';
import OrientationChoicesData from '../../../../data/choices/OrientationChoicesData';

const OrientationField = ({ data, updateField, errorFields }) => {
  return <fieldset>
    <label htmlFor="orientation" className="mb-1">
      <FontAwesomeIcon icon="venus-mars" className="me-2" />
      I am
    </label>
    <DropdownField
      id={'orientation'}
      value={data.orientation}
      choices={OrientationChoicesData}
      onChange={updateField}
      error={0 < errorFields.filter(field => 'orientation' === field.key).length}
      required={true}
    />
    {0 < errorFields.filter(field => 'orientation' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'orientation' === field.key)[0].message}
    </div> : ''}
  </fieldset>
};

export default OrientationField;
