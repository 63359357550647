const RegisterFormState = {
  orientation: '',
  dob: '',
  dob_year: '',
  dob_month: '',
  dob_day: '',
  location: '',
  username: '',
  email: '',
  password: '',
  confirm_password: '',
  confirm: false,
};

export default RegisterFormState;
