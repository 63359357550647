import React from 'react';
import LoadingSection from '../../components/Sections/LoadingSection';

const Loading = () => {
  return <>
    <LoadingSection />
  </>;
};

export default Loading;
