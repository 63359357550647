import Request from '../../system/Request';

const Notifications = {
  get: (data) => {
    const body = {
      page: data.page ?? 1,
      numOfRecords: data.recordsPerPage ?? 20,
    };

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/notifications/all', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  markRead: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/notifications/${notification.id}/read`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  markAllRead: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/notifications/all/read`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/notifications/${notification.id}/delete`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Notifications;
