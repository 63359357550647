const calculateAge = (dob) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const validateFields = (data, FieldValidator) => new Promise((resolve, reject) => {
  let errorFields = [];
  Promise.all(Object.keys(data).map((key) => {
    return FieldValidator(data, key, data[key]).then(() => true).catch((field) => {
      errorFields.push(field);
      return false;
    });
  })).then((result) => result.includes(false) ? reject(errorFields) : resolve());
});

export { calculateAge, validateFields };
