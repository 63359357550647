import Request from '../system/Request';

const Location = {
  getByCountry: (country) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/locations?country=${encodeURIComponent(country)}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  getAllCounties: (country) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/locations/counties?country=${encodeURIComponent(country)}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  getAllCitiesAndTowns: (country, county) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/locations/cities_towns?country=${encodeURIComponent(country)}&county=${encodeURIComponent(county)}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Location;
