import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import FontAwesomeIcon from '../../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const TextSearchField = ({
  id,
  value,
  selection,
  choices = [],
  placeholder,
  charLimit = 0,
  onChange,
  onSelection,
  error = false,
  required = false,
  autoComplete = 'on'
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onChoice = (e, choice) => {
    // Avoid parent click
    e.stopPropagation();

    onSelection(id, choice);
    onChange(id, choice.value);
    setIsComponentVisible(false);
  };

  const onText = (e, value) => {
    onChange(id, value);

    setIsComponentVisible(0 < value.length);
  };

  return <div ref={ref} className={styles.container}>
    <Row>
      <Col xs={11}>
        <div className={styles.holder}>
          <div className={styles.input}>
            <div
              style={{textIndent:'-10000px', height: 0}}
            >
              {value && 0 < value.length ? value : placeholder}
            </div>
            <input
              id={'search_' + id + '_edhfk'}
              name={'search_' + id + '_dfghgfds'}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onText(id, e.target.value)}
              className={error || (0 < charLimit && charLimit < value.toString().length) ? styles.hasError : ''}
              data-lpignore={'true'}
              onClick={(e) => e.preventDefault()}
              autoComplete={autoComplete}
            />
            {charLimit ? <div className={styles.charLimit}>
              Character Limit {value.toString().length}/{charLimit}
            </div> : ''}
          </div>
          <div className={`${styles.choices} ${isComponentVisible ? styles.open : ''}`}>
            {choices.filter(
              c => c.value.toLowerCase().includes(value.toLowerCase())
            ).map((choice, idx) => <div
              key={idx}
              onClick={(e) => onChoice(e, choice)}
              className={`${styles.choice} ${'disable' in choice && choice.disable ? styles.disabled : ''}`}
            >
              {choice.value}
            </div>)}
          </div>
        </div>
      </Col>
      <Col xs={1} className={'p-0'}>
        {!error && selection && charLimit < value.length ? <FontAwesomeIcon
          icon={'times'}
          className={'pt-3'}
        /> : (error || (selection && 0 < value.length)) ? <FontAwesomeIcon
          icon={0 < (selection && 0 < value.length) ? 'check' : 'times'}
          className={`pt-3 ${0 < (selection && 0 < value.length) ? styles.tick + ' green-text' : styles.required + ' red-text'}`}
        /> : required ? <FontAwesomeIcon
          icon={'times'}
          className={`pt-3`}
        /> : ''}
      </Col>
    </Row>
  </div>;
};

export default TextSearchField;
