import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextField from '../_Types/TextField';

const EmailField = ({ data, updateField, errorFields }) => {
  return <fieldset>
    <label htmlFor="email" className="mb-1">
      <FontAwesomeIcon icon="envelope" className="me-2"/>
      Email address
    </label>
    <TextField
      id={'email'}
      type={'email'}
      placeholder={'i.e. me@example.com'}
      value={data.email}
      onChange={updateField}
      required={true}
      autoComplete={'off'}
      error={0 < errorFields.filter(field => 'email' === field.key).length}
      pattern="[A-Za-z0-9_-]*"
    />
    {0 < errorFields.filter(field => 'email' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'email' === field.key)[0].message}
    </div> : ''}
  </fieldset>;
};

export default EmailField;
