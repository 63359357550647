import React, { useState } from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextSearchField from '../_Types/TextSearchField';

const LocationField = ({ data, locations, updateField, errorFields }) => {
  const [locationSearch, setLocationSearch] = useState('');

  return <fieldset>
    <label htmlFor="username" className="mb-1">
      <FontAwesomeIcon icon="globe-stand" className="me-2"/>
      Your location <i>(City / Town)</i> - <span className={'bolder'}>UK Only!</span>
    </label>
    <TextSearchField
      id={'location'}
      placeholder={'Enter your location'}
      value={locationSearch ? locationSearch : data.location ? data.location.value : ''}
      selection={data.location}
      choices={locations}
      onChange={(id, value) => setLocationSearch(value)}
      onSelection={updateField}
      required={true}
      error={0 < errorFields.filter(field => 'location' === field.key).length}
      autoComplete={'off'}
    />
    {0 < errorFields.filter(field => 'location' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'location' === field.key)[0].message}
    </div> : ''}
  </fieldset>;
};

export default LocationField;
