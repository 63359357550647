import Request from '../../../system/Request';

const Photo = {
  upload: (file) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/media/photo/upload', JSON.stringify(file), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  setDefaultProfilePhoto: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).put('/api/users/me/photo', JSON.stringify({
        photoId: photo.id
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Photo;
