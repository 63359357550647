import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const TextField = ({
  id,
  type = 'text',
  value,
  placeholder,
  charLimit = 0,
  onChange,
  readOnly,
  required,
  autoComplete = 'on',
  error = false,
  pattern = undefined
}) => {
  return <div className={styles.container}>
    <Row>
      <Col xs={11} className={error || (0 < charLimit && charLimit < value.toString().length) ? styles.hasError : ''}>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(id, e.target.value)}
          readOnly={readOnly}
          autoComplete={autoComplete}
          pattern={pattern}
        />
        {charLimit ? <div className={styles.charLimit}>
          Max Character Limit {value.toString().length}/{charLimit}
        </div> : ''}
      </Col>
      <Col xs={1} className={'p-0'}>
        {(required && 0 === value.toString().length) || (0 < charLimit && charLimit < value.toString().length) ? <FontAwesomeIcon
          icon={'times'}
          className={'pt-3'}
        /> : required || 0 < value.toString().length ? <FontAwesomeIcon
          icon={!error && 0 < value.toString().length ? 'check' : 'times'}
          className={`pt-3 ${!error && 0 < value.toString().length ? 'text-success' : 'text-danger'}`}
        /> : ''}
      </Col>
    </Row>
  </div>;
};

export default TextField;
