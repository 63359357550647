import Request from '../../system/Request';

const Messages = {
  get: (data, isTwoStep) => {
    const body = {
      page: data.page ?? 1,
      numOfRecords: data.recordsPerPage ?? 20,
    };

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/messages/all', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  threads: (messageId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/messages/${messageId}/threads`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  markRead: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/messages/${notification.id}/read`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  markAllRead: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/messages/all/read`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (notification) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/messages/${notification.id}/delete`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Messages;
