import { calculateAge } from "../utils/helpers";
import OrientationChoicesData from "../data/choices/OrientationChoicesData";

const RegisterValidation = (data, field, value) => new Promise((resolve, reject) => {
  switch (field) {
    case 'orientation':
      const orientationChoice = OrientationChoicesData.filter((c) => c.key === data.orientation);
      if (0 === orientationChoice.length) {
        reject({
          key: 'orientation',
          message: 'Please select an option.'
        });
        return;
      }
      resolve();
      break;
    case 'dob_day':
    case 'dob_month':
    case 'dob_year':
      if (0 < data.dob_year.length && 0 < data.dob_month.length && 0 < data.dob_day.length) {
        if (18 > calculateAge(new Date(`${data.dob_year}-${data.dob_month}-${data.dob_day}`))) {
          reject({
            key: 'dob',
            message: 'You must be at least 18 years old to register.'
          });
          return;
        }
      } else {
        reject({
          key: 'dob',
          message: 'Invalid date of birth.'
        });
        return;
      }
      resolve();
      break;
    case 'location':
      const findLocation = data.locations.filter((l) => l.key === data.location.key);
      if (0 === findLocation.length) {
        reject({
          key: 'location',
          message: 'Please select your location from the list provided.'
        });
        return;
      }
      resolve();
      break;
    case 'username':
      if (!/^[A-Za-z0-9_-]+$/.test(value) || !/[a-zA-Z]/g.test(value) || 0 === value.length) {
        reject({
          key: 'username',
          message: 'Please enter a valid ' + field.replace('_', ' ') + '. Valid characters are letters, numbers, underscores and hyphens.'
        });
        return;
      }
      resolve();
      break;
    case 'email':
      if (String(value).toLowerCase().match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )) {
        resolve();
      } else {
        reject({
          key: 'email',
          message: 'Please enter a valid email address.'
        });
        return;
      }
      resolve();
      break;
    case 'password':
      if (value.length < 8) {
        reject({
          key: 'password',
          message: 'Password must be a minimum of 8 characters.'
        });
        return;
      }
      if (String(value).match(
        /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
      )) {
        reject({
          key: 'password',
          message: 'Password contains invalid characters.'
        });
        return;
      }
      resolve();
      break;
    case 'confirm_password':
      if (value.length < 8) {
        reject({
          key: 'confirm_password',
          message: 'Password must be a minimum of 8 characters.'
        });
        return;
      }
      if (String(value).match(
        /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
      )) {
        reject({
          key: 'confirm_password',
          message: 'Password contains invalid characters.'
        });
        return;
      }
      if (value !== data.password) {
        reject({
          key: 'confirm_password',
          message: 'Passwords do not match.'
        });
        return;
      }
      resolve();
      break;
    case 'confirm':
      if (!value) {
        reject({
          key: 'confirm',
          message: 'You must agree to our terms and conditions to join.'
        });
        return;
      }
      resolve();
      break;
    default:
      resolve();
      break;
  }
});

export default RegisterValidation;
