import React, { useEffect, useState } from 'react';
import { Badge, Button, Container, Nav, Navbar, NavDropdown, Offcanvas, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useApp } from '../../contexts/App';
import { useAccountContext } from '../../contexts/Account';
import Image from '../../components/Image';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import InitAccountStateData from '../../data/InitAccountStateData';
import Api from '../../Api';

import styles from './styles.module.scss';

const MainNav = () => {
  const { appState, dispatchApp } = useApp();
  const { accountState, dispatchAccount, updateAccountFromApi } = useAccountContext();
  const isTablet = useMediaQuery('(max-width: 991px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [useMobileMenu, setUseMobileMenu] = useState(false);

  useEffect(() => {
    if (isTablet === useMobileMenu) return;

    setUseMobileMenu(isTablet);
  }, [isTablet, useMobileMenu]);

  const onToggleSafeSearch = () => {
    Api.Account.toggleSafeSearch().then((response) => {
      updateAccountFromApi();
    }).catch((e) => console.log(e));
  };

  const logout = () => {
    dispatchAccount({ ...InitAccountStateData });
  };

  const openLogin = () => {
    appState.setDialogState('login', true);
    dispatchApp({ ...appState });
  };

  const openRegister = () => {
    appState.setDialogState('register', true);
    dispatchApp({ ...appState });
  };

  return <>
    <Navbar
      id={'header'}
      key={'main-nav'}
      expand={!useMobileMenu || !accountState.user}
      sticky="top"
      className={styles.navbar + ' pt-0 pb-0 ' + (!useMobileMenu ? 'd-none d-lg-block' : 'd-block d-lg-none') }
    >
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          {isMobile ? <Image src={'images/icon'} type={'svg'} width={64} height={64} alt="Only Amour" className={isMobile ? 'p-1' : ''} />: ''}
          <Image src={isMobile ? 'images/logo_text_square' : 'images/logo_main'} type={'svg'} width={isMobile ? 64 : 238} height={64} alt="Only Amour" className={isMobile ? 'p-1' : ''} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`main-menu-nav`} />
        <Navbar.Offcanvas
          id={`main-menu-nav`}
          aria-labelledby={`offCanvasNavbarLabel-expand`}
          placement="end"
          className={styles.offCanvas}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offCanvasNavbarLabel-expand`} className="w-75">
              <Row>
                <Col>
                  <Image src="images/icon" type={'svg'} width={72} height={60} alt="Only Amour" />
                </Col>
                <Col>
                  <div className="fs-4 mt-3 lh-1 text-tertiary">Only Amour</div>
                </Col>
              </Row>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Row className={useMobileMenu ? 'flex-column' : ''}>
            {accountState.user && null !== accountState.user.profileUpdatedAt ? <Col className={useMobileMenu ? 'flex-row' : ''}>
              <Offcanvas.Body className={styles.navLinks + (!useMobileMenu ? ' align-items-center ' : ' align-items-start ') + ' flex-grow-1 pe-3'}>
                <Nav.Link as={NavLink} to={'/'} title={'Home'} className={useMobileMenu ? 'mb-2' : ''}>
                  <Button variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''}>
                    <FontAwesomeIcon icon={'home'} className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`} />
                    <span className={!useMobileMenu ? 'd-none d-xl-inline-block' : ''}>Home</span>
                  </Button>
                </Nav.Link>
                <Nav.Link as={NavLink} to={'/inbox'} title={'Inbox'} className={useMobileMenu ? 'mb-2' : ''}>
                  <Button variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''}>
                    <FontAwesomeIcon icon={'comments'} className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`} />
                    <span className={!useMobileMenu ? 'd-none d-xl-inline-block' : ''}>Inbox</span>
                    <Badge className={useMobileMenu ? 'pull-right mt-1' : 'ms-2'}>{accountState.user.messages}</Badge>
                  </Button>
                </Nav.Link>
                <NavDropdown
                  key={`nav-profile-expand`}
                  id={`nav-profile-expand`}
                  title={<Button variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''} title={'Profile'}>
                    <FontAwesomeIcon icon="id-card" className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`}  />
                    <span className={!useMobileMenu ? 'd-none d-xl-inline-block' : ''}>Profile</span>
                    {useMobileMenu ? <FontAwesomeIcon icon="chevron-down" className={'white-text pull-right mt-1'} /> : ''}
                  </Button>}
                  align={'start'}
                  className={useMobileMenu ? 'w-100 mb-2' : 'me-2'}
                >
                  <NavDropdown.Item key={'edit-profile'} as={NavLink} className={'d-flex align-items-center'} to={'/profile'}>
                    <FontAwesomeIcon icon={'id-card'} className={'text-tertiary me-2'} />
                    <span className={'d-flex'}>Edit Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider key={'profile-divider-1'} />
                  <NavDropdown.Item key={'manage-photos'} as={NavLink} className={'d-flex align-items-center'} to={'/profile/photos'}>
                    <FontAwesomeIcon icon={'images'} className={'text-tertiary me-2'} />
                    <span className={'d-flex'}>Manage Photos</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider key={'profile-divider-2'} />
                  <NavDropdown.Item key={'manage-videos'} as={NavLink} className={'d-flex align-items-center'} to={'/profile/videos'}>
                    <FontAwesomeIcon icon={'video'} className={'text-tertiary me-2'} />
                    <span className={'d-flex'}>Manage Videos</span>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={NavLink} to={'/search'} title={'Search'} className={useMobileMenu ? 'mb-2' : ''}>
                  <Button variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''}>
                    <FontAwesomeIcon icon={'search'} className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`} />
                    <span className={!useMobileMenu ? 'd-none d-xl-inline-block' : ''}>Search</span>
                  </Button>
                </Nav.Link>
                <Nav.Link as={NavLink} to={'/notifications'} className={useMobileMenu ? 'mb-2' : ''}>
                  <Button variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''}>
                    <FontAwesomeIcon icon={'bell'} className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`}/>
                    <span className={!useMobileMenu ? 'd-none d-xl-none d-xxl-inline-block' : ''}>Notifications</span>
                    <Badge className={useMobileMenu ? 'pull-right mt-1' : 'ms-2'}>{accountState.user.notifications}</Badge>
                  </Button>
                </Nav.Link>
                <Nav.Item className={useMobileMenu ? 'mb-2' : ''}>
                  <Button onClick={() => onToggleSafeSearch()} variant={useMobileMenu ? 'outline-dark' : 'secondary'} className={useMobileMenu ? 'text-start white-text w-100' : ''}>
                    <FontAwesomeIcon icon={'shield'} className={`white-text ${!useMobileMenu ? 'me-xl-2' : 'me-2'}`}/>
                    <span className={!useMobileMenu ? 'd-none d-xl-none d-xxl-inline-block' : ''}>Safe Search</span>
                    <Badge className={`${accountState.user.safeSearch ? 'bg-success' : 'bg-danger'} ${useMobileMenu ? 'pull-right mt-1' : 'ms-2'}`}>
                      {accountState.user.safeSearch ? 'on' : 'off'}
                    </Badge>
                  </Button>
                </Nav.Item>
                {isMobile || isTablet ? <NavDropdown
                  key={`nav-mobile-account-expand`}
                  id={`nav-mobile-account-expand`}
                  title={<Button variant={useMobileMenu ? 'outline-primary text-start white-text w-100' : 'secondary'} title={'Profile'}>
                    {accountState.user.image ?
                      <img src={accountState.user.image} height={30} alt="Account Avatar" className={styles.userImg}/> :
                      <FontAwesomeIcon icon={'user'} className={'me-2'}/>}
                    <span className={!useMobileMenu ? 'd-none d-xl-inline-block' : ''}>My Account</span>
                    {useMobileMenu ? <FontAwesomeIcon icon="chevron-down" className={'white-text pull-right mt-1'} /> : ''}
                  </Button>}
                  align={'start'}
                  className={useMobileMenu ? 'w-100 mb-2' : 'me-2'}
                >
                  {null !== accountState.user.profileUpdatedAt ? <>
                    <NavDropdown.Item key={'edit-profile'} as={NavLink} className={'d-flex align-items-center'} to="/profile">
                      <FontAwesomeIcon icon={'id-card'} className={'text-tertiary me-2'} />
                      <span className={'d-flex'}>Edit Profile</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item key={'manage-photos'} as={NavLink} className={'d-flex align-items-center'} to="/profile/photos">
                      <FontAwesomeIcon icon={'images'} className={'text-tertiary me-2'} />
                      <span className={'d-flex'}>Manage Photos</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item key={'manage-videos'} as={NavLink} className={'d-flex align-items-center'} to="/profile/videos">
                      <FontAwesomeIcon icon={'video'} className={'text-tertiary me-2'} />
                      <span className={'d-flex'}>Manage Videos</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item key={'edit-account'} as={NavLink} className={'d-flex align-items-center'} to="/account">
                      <FontAwesomeIcon icon={'user-edit'} className={'text-tertiary me-2'} />
                      <span className={'d-flex'}>Edit Account</span>
                    </NavDropdown.Item>
                    <NavDropdown.Divider key={'account-divider-2'} />
                  </> : ''}
                  <NavDropdown.Item key={'logout'} onClick={() => logout()}>
                    <FontAwesomeIcon icon={'sign-out'} className={'text-tertiary me-2'} />
                    <span>Logout</span>
                  </NavDropdown.Item>
                </NavDropdown> : ''}
              </Offcanvas.Body>
            </Col> : ''}
            {!useMobileMenu || !accountState.user? <Col xs={accountState.user && null !== accountState.user.profileUpdatedAt ? 3 : 12}>
              <Offcanvas.Body>
                <Nav className={styles.navLinks +  ' align-items-center justify-content-end flex-grow-1 pe-3'}>
                  {!accountState ? (
                    <Nav.Link as={NavLink} to={'#'}>
                      <FontAwesomeIcon icon={'spinner'} className={'fa-spin me-2'} />
                    </Nav.Link>
                  ) : accountState.user ? <>
                    <NavDropdown
                      key={`nav-account-expand`}
                      id={`nav-account-expand`}
                      title={<span className={'me-1'}>
                        {accountState.user.image ? <img src={accountState.user.image} height={30} alt="Account Avatar" className={styles.userImg} /> : <FontAwesomeIcon icon={'user'} className={'me-2'} />}
                        {!isMobile ? accountState.user.username : 'My Account'}
                      </span>}
                      align={'end'}
                    >
                      {null !== accountState.user.profileUpdatedAt ? <>
                        <NavDropdown.Item key={'edit-profile'} as={NavLink} className={'d-flex align-items-center'} to="/profile">
                          <FontAwesomeIcon icon={'id-card'} className={'text-tertiary me-2'} />
                          <span className={'d-flex'}>Edit Profile</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item key={'manage-photos'} as={NavLink} className={'d-flex align-items-center'} to="/profile/photos">
                          <FontAwesomeIcon icon={'images'} className={'text-tertiary me-2'} />
                          <span className={'d-flex'}>Manage Photos</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item key={'manage-videos'} as={NavLink} className={'d-flex align-items-center'} to="/profile/videos">
                          <FontAwesomeIcon icon={'video'} className={'text-tertiary me-2'} />
                          <span className={'d-flex'}>Manage Videos</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item key={'edit-account'} as={NavLink} className={'d-flex align-items-center'} to="/account">
                          <FontAwesomeIcon icon={'user-edit'} className={'text-tertiary me-2'} />
                          <span className={'d-flex'}>Edit Account</span>
                        </NavDropdown.Item>
                        <NavDropdown.Divider key={'account-divider-2'} />
                      </> : ''}
                      <NavDropdown.Item key={'logout'} onClick={() => logout()}>
                        <FontAwesomeIcon icon={'sign-out'} className={'text-tertiary me-2'} />
                        <span>Logout</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </> : <>
                    <Nav.Item>
                      <Button size={'sm'} variant={'primary'} onClick={openLogin} className={`${styles.login} me-3`}>
                        <FontAwesomeIcon icon={'sign-in'} className={'me-2'} />
                        Login
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button size={'sm'} variant={'primary'} onClick={openRegister} className={`${styles.register}`}>
                        <FontAwesomeIcon icon={'user-edit'} className={'secondary text-white me-1'} />
                        {isMobile ? 'Signup' : 'Create free account'}
                      </Button>
                    </Nav.Item>
                  </>}
                </Nav>
              </Offcanvas.Body>
            </Col>: ''}
          </Row>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </>;
};

export default MainNav;
