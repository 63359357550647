import Account from './api/Account';
import Location from './api/Location';
import Notify from './api/Notify';
import Search from './api/Search';
import Member from './api/Member';

const Api = {
  Account: Account,
  Location: Location,
  Notify: Notify,
  Search: Search,
  Member: Member,
};

export default Api;
