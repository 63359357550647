import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const DobField = ({ data, updateField, errorFields, error = false }) => {
  return <fieldset>
    <label htmlFor="username" className="mb-1">
      <FontAwesomeIcon icon="calendar-alt" className="me-2"/>
      Date of Birth
    </label>
    <Row>
      <Col xs={11}>
        <div className={`${styles.inputGroup} d-flex`}>
          <input
            type="text"
            id="dob_day"
            name="dob_day"
            value={data.dob_day}
            onChange={updateField}
            placeholder="Day"
            onKeyDown={(e) => (48 > e.which || e.which > 57) ? (e.key.length === 1) ? e.preventDefault() : e : e}
            className={error ? styles.error : ''}
            autoComplete={'off'}
            pattern="[0-9]+"
            maxLength={2}
            required
          />
          <input
            type="text"
            id="dob_month"
            name="dob_month"
            value={data.dob_month}
            onChange={updateField}
            placeholder="Month"
            onKeyDown={(e) => (48 > e.which || e.which > 57) ? (e.key.length === 1) ? e.preventDefault() : e : e}
            className={error ? styles.error : ''}
            autoComplete={'off'}
            pattern="[0-9]+"
            maxLength={2}
            required
          />
          <input
            type="text"
            id="dob_year"
            name="dob_year"
            value={data.dob_year}
            onChange={updateField}
            placeholder="Year"
            onKeyDown={(e) => (48 > e.which || e.which > 57) ? (e.key.length === 1) ? e.preventDefault() : e : e}
            className={error ? styles.error : ''}
            autoComplete={'off'}
            pattern="[0-9]+"
            maxLength={4}
            required
          />
        </div>
      </Col>
      <Col xs={1} className={'p-0 pt-2'}>
        {!error && 0 < data.dob.length ? <div className={'text-success'}>
          <FontAwesomeIcon icon={'check'} className={'me-2'}/>
        </div> : <FontAwesomeIcon
          icon={'times'}
          className={0 < errorFields.filter(field => 'dob' === field.key).length ? 'red-text' : ''}
        />}
      </Col>
    </Row>
    {0 < errorFields.filter(field => 'dob' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'dob' === field.key)[0].message}
    </div> : 0 === data.dob.length ? <div className={'text-danger'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      Over 18's only!
    </div> : ''}
  </fieldset>;
};

export default DobField;
