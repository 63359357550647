import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../LoadingSpinner';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import FontAwesomeIcon from '../../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const DropdownField = ({
  id,
  value,
  choices = [],
  onChange,
  error = false,
  readOnly = false,
  required = false
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onSelection = (e, value) => {
    // Avoid parent click
    e.stopPropagation();

    onChange(id, value);
    setIsComponentVisible(false);
  };

  return <div ref={ref} className={styles.container}>
    <Row>
      <Col xs={11}>
        <div className={`${styles.dropdown} ${readOnly ? styles.dropdown__readOnly : ''} ${error ? styles.dropdown__error : ''}`}>
          <div onClick={() => !readOnly ? setIsComponentVisible(!isComponentVisible) : ''} className={styles.selected}>
            <div className={`${styles.text} ${0 < value.toString().length ? styles.hasValue : ''}`}>
              {0 === choices.length ? <>
                <LoadingSpinner size={'small'} />&nbsp; Loading...
              </> : 0 === value.toString().length ? 'Please select an option' : choices.filter(c => c.key === value).map(c => c.value)[0]}
            </div>
            <div className={styles.icons}>
              <FontAwesomeIcon icon={isComponentVisible ? 'chevron-up' : 'chevron-down'} />
            </div>
          </div>
          <div className={`${styles.choices} ${isComponentVisible ? styles.open : ''}`}>
            {choices.map((c, idx) => 0 !== c.value.length ? <div
              key={idx}
              onClick={(e) => onSelection(e, c.key)}
              className={`${styles.choice} ${c.key === value ? styles.selected : ''} ${'disable' in c && c.disable ? styles.disabled : ''}`}
            >
              {t(c.value)}
            </div> : '')}
          </div>
        </div>
      </Col>
      <Col xs={1} className={'p-0'}>
        {!error && 0 === value.toString().length ? <FontAwesomeIcon
          icon={'times'}
          className={'pt-3'}
        /> : error || required || 0 < value.toString().length ? <FontAwesomeIcon
          icon={0 < value.toString().length ? 'check' : 'times'}
          className={`pt-3 ${!error && 0 < value.toString().length ? 'text-success' : 'text-danger'}`}
        /> : ''}
      </Col>
    </Row>
  </div>;
};

export default DropdownField;
