import React from 'react';
import { useMediaQuery } from "usehooks-ts";
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useAccountContext } from '../../contexts/Account';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import EnglandCitiesData from '../../data/EnglandCitiesData';

import styles from './styles.module.scss';

const Footer = () => {
  const { accountState } = useAccountContext();
  const location = useLocation();
  const year = new Date().getFullYear();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 900px)');

  return <>
    {'/feedback' !== location.pathname ? <Button to={'/feedback'} as={Link} className={styles.feedback}>
      <FontAwesomeIcon icon={'lightbulb-on'} className={'me-2'} />
      Feedback
    </Button> : ''}
    <footer className={styles.footer}>
      <Row>
        <Col>
          <Container className={(isTablet ? 'w-100 m-0' : '') + ' pt-4 pb-4'} style={isTablet ? { maxWidth: '100%' } : {}}>
            <Row className="pb-4">
              <Col xs={12} md={12} lg={4} className="">
                <div className={'d-flex align-items-center align-items-lg-start flex-column flex-md-row flex-lg-column flex-xl-row'}>
                  <Image src="images/logo_main" type={'svg'} width={150} alt="Only Amour" className={styles.logo} />
                  <div className={'p-2'}>
                    {year} <span className={'text-tertiary'}>&copy;</span> Only Amour<sup
                    className={'text-tertiary'}>&reg;</sup>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={8} xxl={8} className={'p-0 text-center text-md-start text-lg-end text-xl-end text-xxl-end'}>
                <Button variant="outline-dark" className={'m-1 white-text'} size={'sm'} target="_blank" rel="noreferrer" href="https://status.onlyamour.com">
                  <FontAwesomeIcon icon={'history'} className={'text-success ' + (isMobile ? 'me-1' : 'me-2')} />
                  Service Status
                </Button>
                <Button variant="outline-dark" className={'m-1 white-text'} size={'sm'} as={Link} to="/locations">
                  <FontAwesomeIcon icon={'map-marker-alt'} className={'text-info ' + (isMobile ? 'me-1' : 'me-2')} />
                  Locations
                </Button>
                <Button variant="outline-dark" className={'m-1 white-text'} size={'sm'} as={Link} to="/legal/safe-online-dating">
                  <FontAwesomeIcon icon={'user-shield'} className={'text-success ' + (isMobile ? 'me-1' : 'me-2')} />
                  Safe Online Dating
                </Button>
                <Button variant="outline-dark" className={'m-1 white-text'} size={'sm'} as={Link} to="/contact">
                  <FontAwesomeIcon icon={'envelope'} className={'text-tertiary ' + (isMobile ? 'me-1' : 'me-2')} />
                  Contact
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={'d-flex'}>
                  <div className={styles.ageRestriction + ' me-3'}>18+</div>
                  <p className={'fs-9'}>
                    Only Amour is strictly 18+ only. Our service is strictly for consenting adults seeking discreet, casual arrangements with other like-minded people located all around the United Kingdom covering all major towns and cities.
                    To maintain the integrity and authenticity of the service we offer we use human moderators to check profiles and all uploaded content. We also allow members to rate and verify other members.
                    The safety of our members is paramount to Only Amour. Please view our <Link to={'/legal/safe-online-dating'}>Safe Online Dating</Link> guide on how to stay safe while dating online.
                  </p>
                </div>
                <p className={'fs-9 bolder'}>
                  Over 18 years old only. Only Amour &copy; is a UK based adult contact service.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={styles.bottom}>
        <Col>
          <Container className={(isTablet ? 'w-100 m-0' : '') + ' pt-4 pb-4'} style={isTablet ? { maxWidth: '100%' } : {}}>
            <Row className={'mb-2'}>
              <Col>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/about">
                  <Icon name={'icon'} width={16} height={16} className={'me-2'} />
                  Only Amour
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/terms#anti-sex-trafficking">
                  <FontAwesomeIcon icon={'octagon'} className={'text-danger ' + (isMobile ? 'me-1' : 'me-2')} />
                  Anti-Sex Trafficking
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/abuse">
                  <FontAwesomeIcon icon={'exclamation-triangle'} className={'text-danger ' + (isMobile ? 'me-1' : 'me-2')} />
                  Abuse
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/report">
                  <FontAwesomeIcon icon={'paper-plane'} className={'text-warning ' + (isMobile ? 'me-1' : 'me-2')} />
                  Report an Issue
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/website-security">
                  <FontAwesomeIcon icon={'shield-check'} className={'text-success ' + (isMobile ? 'me-1' : 'me-2')} />
                  Website Security
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/terms#dmca-notice">
                  <FontAwesomeIcon icon={'lock'} className={'text-success ' + (isMobile ? 'me-1' : 'me-2')} />
                  DMCA Notice
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/privacy">
                  <FontAwesomeIcon icon={'user-secret'} className={'text-tertiary ' + (isMobile ? 'me-1' : 'me-2')} />
                  Privacy Policy
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/cookie">
                  <FontAwesomeIcon icon={'cookie'} className={'text-tertiary ' + (isMobile ? 'me-1' : 'me-2')} />
                  Cookie Policy
                </Button>
                <Button variant="dark" className={'m-1 fs-9 white-text'} size={'sm'} as={Link} to="/legal/terms">
                  <FontAwesomeIcon icon={'gavel'} className={'text-tertiary ' + (isMobile ? 'me-1' : 'me-2')} />
                  Terms & Conditions
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-md-left">
                {EnglandCitiesData.map((keyword) => (
                  <Link key={keyword.toLowerCase().replace(' ', '-')} to={accountState.user ? '/search' : '/register'}
                        className={(!isMobile ? 'fs-9' : 'fs-10') + ' inline-block d-inline-block p-2 pt-0 pb-1 text-grey'}>
                    <small>Amour in {keyword}</small>
                  </Link>
                ))}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </footer>
  </>;
};

export default Footer;
