import React, { createContext, useContext, useState } from 'react'
import InitAccountStateData from '../data/InitAccountStateData';
import Cache from '../system/Cache';
import Api from '../Api';

export const AccountContext = createContext({
  state: InitAccountStateData,
  isAuthenticated: false,
  dispatchAccount: () => null,
  updateAccountFromApi: () => null,
});

export const AccountProvider = ({ children }) => {
  // const crypto = new CryptoService();
  const cache = new Cache();

  const [accountState, setAccountState] = useState(() => {
    const localAccountState = cache.get('account');

    return null === localAccountState ? InitAccountStateData : localAccountState;
  });

  const dispatchAccount = (account) => {
    // Make sure we grab the latest saved tokens before dispatching the new state.
    const latestCache = cache.get('account');
    if (latestCache && null !== account.user) {
      account.token = latestCache.token;
      account.refresh_token = latestCache.refresh_token;
    }

    cache.set('account', account);
    setAccountState({ ...account });
  };

  const updateAccountFromApi = () => {
    Api.Account.get().then((user) => {
      dispatchAccount({
        ...accountState,
        user: user
      });
    }).catch((e) => console.log(e));
  };

  const isAuthenticated = !!accountState.token;

  return <AccountContext.Provider value={{ accountState, isAuthenticated, dispatchAccount, updateAccountFromApi }}>
    { children }
  </AccountContext.Provider>;
};

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAuth must be used within an AccountProvider');
  }

  return context;
};
