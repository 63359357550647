import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAccountContext } from '../contexts/Account';

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const { accountState, isAuthenticated } = useAccountContext();
  if (![
    '/profile',
    '/profile/photos',
    '/profile/videos',
  ].includes(location.pathname) && accountState.user && null === accountState.user.profileUpdatedAt) {
    return <Navigate to="/profile" />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoutes;
