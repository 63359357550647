import Request from '../../../system/Request';

const Video = {
  upload: (file) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/media/video/upload', JSON.stringify(file), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Video;
