import Request from '../../system/Request';
import Photo from './Media/Photo';
import Video from './Media/Video';

const Media = {
  Photo: Photo,
  Video: Video,

  setPublic: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).put('/api/media/public', JSON.stringify({
        photoId: photo.id
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  setPrivate: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).put('/api/media/private', JSON.stringify({
        photoId: photo.id
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/media/delete/${photo.id}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Media;
