import Request from '../system/Request';

const Member = {
  get: (username) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/profile/member/${username}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  flirt: (username, emojiName) => {
    return new Promise((resolve, reject) => {
      (new Request()).post(`/api/profile/member/${username}/flirt`, JSON.stringify({
        flirt: emojiName,
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  message: (username, message, mediaId = null) => {
    return new Promise((resolve, reject) => {
      (new Request()).post(`/api/profile/member/${username}/message`, JSON.stringify({
        mediaId: mediaId,
        message: message,
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  mediaViewed: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/profile/member/media/${photo.id}/viewed`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  mediaLiked: (photo) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/profile/member/media/${photo.id}/liked`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Member;
