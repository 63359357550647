import React from 'react';

import styles from './styles.module.scss';

const Icon = ({ name, width, height, alt, title, className = styles.img }) => {
  const basename = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME.trimEnd() : '';

  return <img
    loading="lazy"
    src={`${basename}/images/${name}.svg`}
    width={width}
    height={height}
    alt={alt}
    title={title}
    className={className}
    style={{ width: width, height: height }}
  />;
};

export default Icon;
