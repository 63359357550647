import React, { useRef, useState, useEffect } from 'react';
import { useApp } from '../../contexts/App';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { validateFields } from '../../utils/helpers';
import FontAwesomeIcon from '../FontAwesomeIcon';
import DobField from '../Form/Fields/DobField';
import OrientationField from '../Form/Fields/OrientationField';
import LocationField from '../Form/Fields/LocationField';
import UsernameField from '../Form/Fields/UsernameField';
import EmailField from '../Form/Fields/EmailField';
import PasswordField from '../Form/Fields/PasswordField';
import RegisterValidation from '../../validation/RegisterValidation';
import RegisterFormState from '../../data/form/RegisterFormState';
import Api from '../../Api';

import styles from './styles.module.scss';

const Register = () => {
  const { appState, dispatchApp } = useApp();
  const [loading, setLoading] = useState(false);
  const hasLoaded = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState(RegisterFormState);
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    Api.Location.getByCountry('United Kingdom').then((response) => {
      let locations = [];

      response.data.forEach(location => {
        if (0 < location.county.length && 0 < location.city.length) {
          locations.push({
            key: location.id,
            value: `${location.county} - ${location.city}`,
            disable: false
          });
        }
      });

      setLocations(locations);
    });
  }, []);

  const openLogin = () => {
    appState.setDialogState('login', true);
    appState.setDialogState('register', false);

    dispatchApp({ ...appState });
  };

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    newData['dob'] = 0 !== data.dob_year.length && 0!== data.dob_month.length && 0!== data.dob_day.length ? `${data.dob_year}-${data.dob_month}-${data.dob_day}` : '';

    setData(newData);
    setErrorFields([]);
  };

  const updateFieldById = (id, value) => {
    const newData = { ...data };
    newData[id] = value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    validateFields({ ...data, locations: locations }, RegisterValidation).then(() => {
      Api.Account.register(data, locations).then((response) => {
        setLoading(false);

        if (undefined === response.violations) {
          setSuccessMessage(<>
            <div className={'mb-2 bold'}>Account created successfully!</div>
            Please <span className={'bold text-decoration-underline'}>activate</span> your account by clicking the link in the email we sent you.
            If you can't see the email, please check your spam / junk folders.
          </>);
        } else {
          response.violations.forEach((violation) => {
            if ('This value should not be blank.' === violation.message) return;

            setErrorFields([{
              key: violation.propertyPath,
              message: 'This value is already used.' === violation.message ? 'Error registering account' : violation.message
            }]);
          })
        }
      }).catch((e) => {
        setLoading(false);
        if (e?.body?.fields) {
          let newErrorFields = [];
          e.body.fields.forEach((field) => {
            newErrorFields.push({
              key: field.key,
              message: field.message
            });
          });
          setErrorFields(newErrorFields);
        } else {
          setErrorFields([{
            key: 'register',
            message: 'An error occurred, please try again!'
          }])
        }
      });
    }).catch((fields) => {
      let newErrorFields = [];
      fields.forEach((field) => {
        newErrorFields.push(field);
      });
      setErrorFields(newErrorFields);
      setLoading(false);
    });
  };

  return <form id={'register_dialog'} onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
    {successMessage ? <Row>
      <Col className={'mt-3'}>
        <Alert variant={'success'}>
          {successMessage}
        </Alert>
      </Col>
    </Row> : <Row>
    {0 < errorFields.length && 0 < errorFields.filter(field => ['register'].includes(field.key)).length ? <Col xs={12} className="mt-3 mb-3">
      <Alert variant="danger" className="p-2 m-0">
        <strong className="bold me-3">
          <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
          Error
        </strong>
        {errorFields.filter(field => 'register' === field.key)[0].message}
      </Alert>
    </Col> : ''}
      <Col xs={12} md={6} className="mt-3">
        <OrientationField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <DobField
          data={data}
          updateField={updateField}
          errorFields={errorFields}
          error={0 < errorFields.filter(field => 'dob' === field.key).length}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <LocationField
          data={data}
          locations={locations}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <UsernameField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <EmailField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          value={data.password}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          id={'confirm_password'}
          label={'Confirm Password'}
          placeholder={'Confirm your password'}
          value={data.confirm_password}
          updateField={updateFieldById}
          errorFields={errorFields}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <div className={'d-flex'}>
          <fieldset className={'me-3'} style={{ width: '40px' }}>
            <input
              type="checkbox"
              id="confirm"
              name="confirm"
              value={true}
              onChange={updateField}
              className={errorFields.includes('confirm') ? styles.input__error : ''}
              autoComplete={'off'}
              required
            />
          </fieldset>
          <label htmlFor="confirm" className="mb-1 w-75p">
            <span className={'text-tertiary bolder'}>Accept Terms &amp; Conditions and Privacy Policy</span>
            <p className={'fs-8'}>
              18+ only. Please click the checkbox to confirm that you are 18 years old or older and you agree to our <Link to={'/legal/terms'}>terms & conditions</Link> and <Link to={'/legal/privacy'}>privacy policy</Link>. Make sure you read and fully understand our terms before joining.
            </p>
          </label>
        </div>
        {0 < errorFields.filter(field => 'confirm' === field.key).length ? <div className={'fs-8 red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'confirm' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} className="mt-3">
        <Row>
        <Col>
            <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
              <FontAwesomeIcon icon={loading ? 'spinner' : 'check'} className="me-2"/>
              {loading ? 'Loading...' : 'Create free account!'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 text-center">
            Already have an account?
            <button type={'button'} onClick={openLogin} className="ms-2 transparent border-0 text-tertiary">
              Login
            </button>
          </Col>
        </Row>
      </Col>
    </Row>}
  </form>;
};

export default Register;
