const OrientationChoicesData = [{
  key: 'Man->Woman',
  value: 'A man looking for a woman',
  disable: false
}, {
  key: 'Woman->Man',
  value: 'A woman looking for a man',
  disable: false
}, {
  key: 'Man->Man',
  value: 'A man looking for a man',
  disable: false
}, {
  key: 'Woman->Woman',
  value: 'A woman looking for a woman',
  disable: false
}];

export default OrientationChoicesData;
