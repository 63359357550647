import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Toast } from 'react-bootstrap';
import { useMediaQuery } from 'usehooks-ts';
import { useAccountContext } from '../../contexts/Account';
import { useApp } from '../../contexts/App';
import Moment from 'moment';
import MainNav from '../MainNav';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Icon from '../../components/Icon';
import Api from '../../Api';

import styles from './styles.module.scss';
import '../../scss/layout/header.scss';

const Header = () => {
  const { accountState, dispatchAccount, isAuthenticated } = useAccountContext();
  const { appState, dispatchApp } = useApp();
  const navigate = useNavigate();
  const hasLoaded = useRef(false);
  const matches = useMediaQuery('(max-width: 992px)');
  const [timer, setTimer] = useState(0);
  const [useMobileMenu, setUseMobileMenu] = useState(false);

  const onCloseToast = (key) => {
    appState.setToastState(key, null);
    dispatchApp({ ...appState });
  };

  const onToastNavigate = (key, page) => {
    appState.setToastState(key, null);
    dispatchApp({ ...appState });
    navigate(page);
  }

  useEffect(() => {
    if (matches === useMobileMenu) return;

    setUseMobileMenu(matches);
  }, [matches, useMobileMenu]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    const setHeaderSpace = () => {
      if (undefined === document.getElementById('header') || 0 === document.getElementById('header').offsetHeight) return;

      document.getElementById('root').style.paddingTop = document.getElementById('header').offsetHeight + 'px';
    };

    window.addEventListener('resize', () => {
      setHeaderSpace();
    });

    setHeaderSpace();

    setInterval(() => {
      if (!isAuthenticated) return;
      Api.Account.counts().then((counts) => {
        if (accountState.user.notifications < counts.notifications) {
          appState.setToastState('newNotification', {
            count: counts.notifications - accountState.user.notifications,
            date: Moment.now()
          });
          setTimer(1);
        }
        if (accountState.user.messages < counts.messages) {
          appState.setToastState('newMessage', {
            count: counts.messages - accountState.user.messages,
            date: Moment.now()
          });
          setTimer(1);
        }

        if (accountState.user.messages !== counts.messages || accountState.user.notifications !== counts.notifications) {
          dispatchApp({ ...appState });

          const newAccountState = { ...accountState };
          newAccountState.user.messages = counts.messages;
          newAccountState.user.notifications = counts.notifications;

          dispatchAccount({ ...newAccountState });
        }
      });
    }, (1000 * 30));
  }, [accountState, appState, dispatchAccount, dispatchApp, isAuthenticated]);

  useEffect(() => {
    if (!appState.getToastState('newNotification') && !appState.getToastState('newMessage')) return;

    setTimeout(() => {
      setTimer(timer + 1);
    }, 1000);
  }, [appState, timer]);

  return <>
    {null !== appState.getToastState('newNotification') ? <div className={styles.floating}>
      <Toast bg={'dark'} animation={true}  onClose={() => onCloseToast('newNotification')}>
        <Toast.Header bg={'dark'} closeVariant={'white'} className={'grey darken-4 text-white'}>
          <Icon name={'icon'} width={20} height={20} className="rounded me-2" />
          <strong className="text-tertiary bold me-auto">New Notification{1 < appState.getToastState('newNotification')?.count ? 's' : ''}</strong>
          <small className={'text-white'}>{Moment(appState.getToastState('newNotification')?.date).fromNow()}</small>
        </Toast.Header>
        <Toast.Body className={'d-flex'}>
          <div className={'d-flex flex-column flex-fill align-items-start justify-content-center'}>
            <div>
              You have <span className={'bolder text-tertiary'}>{appState.getToastState('newNotification')?.count}</span> new notification{1 < appState.getToastState('newNotification')?.count ? 's' : ''}
            </div>
          </div>
          <div className={'d-flex flex-column text-end'}>
            <Button onClick={() => onToastNavigate('newNotification', '/notifications')} size={'sm'} className={'pull-right'}>
              view now
              <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'} />
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </div> : ''}
    {null !== appState.getToastState('newMessage') ? <div className={styles.floating}>
      <Toast bg={'dark'} animation={true}  onClose={() => onCloseToast('newMessage')}>
        <Toast.Header bg={'dark'} closeVariant={'white'} className={'grey darken-4 text-white'}>
          <Icon name={'icon'} width={20} height={20} className="rounded me-2" />
          <strong className="text-tertiary bold me-auto">New Message{1 < appState.getToastState('newMessage')?.count ? 's' : ''}</strong>
          <small className={'text-white'}>{Moment(appState.getToastState('newMessage')?.date).fromNow()}</small>
        </Toast.Header>
        <Toast.Body className={'d-flex'}>
          <div className={'d-flex flex-column flex-fill align-items-start justify-content-center'}>
            <div>
              You have <span className={'bolder text-tertiary'}>{appState.getToastState('newMessage')?.count}</span> new message{1 < appState.getToastState('newMessage')?.count ? 's' : ''}
            </div>
          </div>
          <div className={'d-flex flex-column text-end'}>
            <Button onClick={() => onToastNavigate('newMessage', '/inbox')} size={'sm'} className={'pull-right'}>
              view now
              <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'} />
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </div> : ''}
    <MainNav />
  </>;
};

export default Header;
