class App {
  version = 2.1;
  dialogState = {
    login: false,
    register: false,
    recover: false,
  };
  toastState = {
    newNotification: null,
    newMessage: null,
  };

  getVersion = () => {
    return this.version ?? 0;
  }

  setToastState = (key, state) => {
    this.toastState[key] = state;
  }

  getToastState = (key) => {
    return this.toastState[key];
  }

  setDialogState = (key, state) => {
    this.dialogState[key] = state;
  }

  getDialogState = (key) => {
    return this.dialogState[key];
  }
}

export default App;
