const MetaData = (path) => {
  const data = {
    '/': {
      title: `Welcome to Only Amour | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Only Amour is strictly 18+ only. Our service is strictly for consenting adults seeking discreet, casual arrangements with other like-minded people located all around the United Kingdom covering all major towns and cities.',
    },
    'Home': {
      title: `Welcome to Only Amour | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Only Amour is strictly 18+ only. Our service is strictly for consenting adults seeking discreet, casual arrangements with other like-minded people located all around the United Kingdom covering all major towns and cities.',
    },
    '/account': {
      title: `My Account | Only Amour`,
      description: 'Manage your online account and profile page.',
    },
    '/profile': {
      title: `My Profile | Only Amour`,
      description: 'Manage your online profile page.',
    },
    '/profile/photos': {
      title: `Photos | My Profile | Only Amour`,
      description: 'Manage your online profile photos.',
    },
    '/profile/videos': {
      title: `Videos | My Profile | Only Amour`,
      description: 'Manage your online profile videos.',
    },
    '/inbox': {
      title: `Inbox | Only Amour`,
      description: 'View your inbox messages from other members.',
    },
    '/notifications': {
      title: `Notifications | Only Amour`,
      description: 'View your account notifications.',
    },
    '/about': {
      title: `About Only Amour | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'We are a UK website, our service is strictly for consenting adults seeking discreet, casual arrangements with other like-minded people located all around the United Kingdom covering all major towns and cities.',
    },
    '/contact': {
      title: `About Only Amour | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Our service is strictly for consenting adults seeking discreet, casual arrangements with other like-minded people located all around the United Kingdom covering all major towns and cities.',
    },
    '/report': {
      title: `Report an Issue | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'If you have a complaint or if you want to report an issue or any criminal or inappropriate behaviour please use the form provided to report it.',
    },
    '/legal/privacy': {
      title: `Privacy Policy | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Only Amour is committed to treating your personal information with a high degree of care. Your personal information will never be shared or sold to third parties.',
    },
    '/legal/safe-online-dating': {
      title: `Safe Online Dating | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Over 9 million people in the UK use online dating websites to find and meet people and most have an enjoyable and safe experience but its worth remembering the fact that there are many other things that can go wrong. Online dating is a dangerous game and it is important to be aware of what you are doing online.',
    },
    '/legal/terms': {
      title: `Terms & Conditions | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'You must be 18 (EIGHTEEN) years old or older in order to use this website. By using and/or viewing this website, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all of its terms and conditions.',
    },
    '/legal/cookie': {
      title: `Cookie Policy | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'When visiting our website, cookies are stored on your computer. Cookies are small text files that are stored by your browser on your device to save certain information.',
    },
    '/search': {
      title: `Search | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Find members from all around the UK looking for love affairs, secret affairs, discreet affairs, marriage affairs and more.',
    },
    '/locations': {
      title: `Locations | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Select a location to find members from all around the UK looking for love affairs, secret affairs, discreet affairs, marriage affairs and more.',
    },
    '/abuse': {
      title: `Report Abuse | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Report a member for abuse, sexual harassment or other abusive behaviours.',
    },
    '/login': {
      title: `Login | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Login to our website to find members from all around the UK looking for love affairs, secret affairs, discreet affairs, marriage affairs and more.',
    },
    '/register': {
      title: `Register | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Register on our website today for FREE to find members from all around the UK looking for love affairs, secret affairs, discreet affairs, marriage affairs and more.',
    },
    '/feedback': {
      title: `Feedback | Love Affairs | Secret Affairs | UK Affairs`,
      description: 'Let us know your feedback, suggestions or ideas so we can improve our website to give you a better experience.',
    },
  };

  return data[path];
};

export default MetaData;
